@import url('https://fonts.googleapis.com/css2?family=Poppins&display=swap');
@tailwind base;
@tailwind components;
@tailwind utilities;

*{
    font-family: 'Poppins', sans-serif;
    
}


.Toastify__toast-body {
    margin: 0 !important;
    padding: 4px 8px !important;
  }
  
  
  .Toastify__toast {
    min-height: 5px !important;
    width: 380px;
  }



  ::-webkit-scrollbar {
    width: 8px;
  }
  
  /* Track  */
  ::-webkit-scrollbar-track {
    background: #b123aa;
    border-radius: 10px;

  }
  
  /* Handle */
  ::-webkit-scrollbar-thumb {
    background: #70057c;
    border-radius: 10px;
  }
  
  /* Handle on hover */
  ::-webkit-scrollbar-thumb:hover {
    background: #5c0466;
  }
  
  .react-tel-input .special-label {
    display: none !important;
}

.react-tel-input .form-control {
  padding: 12px 14px 12px 58px  !important;
}


input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}



input[type="file"] {
  display: none;
}
.custom-file-upload {
  display: inline-block;
  cursor: pointer;
}




body {
  background-color: #efefef;
}

.profile-pic {
    width: 200px;
    max-height: 200px;
    display: inline-block;
}

.file-upload {
    display: none;
}
.circle {
    border-radius: 100% !important;
    overflow: hidden;
    width: 128px;
    height: 128px;
    border: 2px solid rgba(255, 255, 255, 0.2);
    position: absolute;

}
img {
    max-width: 100%;
    height: auto;
}
.p-image {
  position: absolute;
  top: 167px;
  right: 30px;
  color: #666666;
  transition: all .3s cubic-bezier(.175, .885, .32, 1.275);
}
.p-image:hover {
  transition: all .3s cubic-bezier(.175, .885, .32, 1.275);
  color: #666666;
}
.upload-button {
  font-size: 2rem;
}

.upload-button:hover {
  transition: all .3s cubic-bezier(.175, .885, .32, 1.275);
  color: #999;
}